import buildingConstruction0 from "../../images/buildingConstruction0.jpeg";
import buildingConstruction1 from "../../images/buildingConstruction1.jpg";
import buildingConstruction2 from "../../images/buildingConstruction2.jpeg";
import buildingConstruction3 from "../../images/buildingConstruction3.jpg";
import buildingConstruction4 from "../../images/buildingConstruction4.jpg";
import buildingConstruction5 from "../../images/buildingConstruction5.jpg";
import buildingConstruction6 from "../../images/buildingConstruction6.jpg";
import buildingConstruction7 from "../../images/buildingConstruction7.jpg";
import buildingConstruction8 from "../../images/buildingConstruction8.jpg";
import buildingConstruction9 from "../../images/buildingConstruction9.jpg";
import buildingConstruction10 from "../../images/buildingConstruction10.jpg";
import buildingConstruction11 from "../../images/buildingConstruction11.jpg";
import buildingConstruction12 from "../../images/buildingConstruction12.jpg";
import buildingConstruction13 from "../../images/buildingConstruction13.jpg";
import buildingConstruction14 from "../../images/buildingConstruction14.jpg";
import buildingConstruction15 from "../../images/buildingConstruction15.jpg";
import buildingConstruction16 from "../../images/buildingConstruction16.jpeg";
import buildingConstruction17 from "../../images/buildingConstruction17.jpeg";
import buildingConstruction18 from "../../images/buildingConstruction18.jpg";
import buildingConstruction19 from "../../images/buildingConstruction19.jpg";
import buildingConstruction20 from "../../images/buildingConstruction20.jpg";
import buildingConstruction21 from "../../images/buildingConstruction21.jpg";
import buildingConstruction22 from "../../images/buildingConstruction22.jpg";
import buildingConstruction23 from "../../images/buildingConstruction23.jpg";
import buildingConstruction24 from "../../images/buildingConstruction24.jpg";
import buildingConstruction25 from "../../images/buildingConstruction25.jpg";
import buildingConstruction26 from "../../images/buildingConstruction26.jpg";
import buildingConstruction27 from "../../images/buildingConstruction27.jpg";
import buildingConstruction28 from "../../images/buildingConstruction28.jpg";
import buildingConstruction29 from "../../images/buildingConstruction29.png";
import buildingConstruction30 from "../../images/buildingConstruction30.jpg";
import buildingConstruction31 from "../../images/buildingConstruction31.jpg";
import buildingConstruction32 from "../../images/buildingConstruction32.jpg";
import buildingConstruction33 from "../../images/buildingConstruction33.jpg";
import buildingConstruction34 from "../../images/buildingConstruction34.jpg";
import buildingConstruction35 from "../../images/buildingConstruction35.jpg";
import buildingConstruction36 from "../../images/buildingConstruction36.jpg";
import buildingConstruction37 from "../../images/buildingConstruction37.jpg";
import buildingConstruction38 from "../../images/buildingConstruction38.jpg";
import buildingConstruction39 from "../../images/buildingConstruction39.jpg";
import buildingConstruction40 from "../../images/buildingConstruction40.jpg";
import buildingConstruction41 from "../../images/buildingConstruction41.jpg";
import buildingConstruction42 from "../../images/buildingConstruction42.jpg";
import buildingConstruction43 from "../../images/buildingConstruction43.jpg";
import buildingConstruction44 from "../../images/buildingConstruction44.jpg";
 
 export default function () {
    const newsToShow = [
        {
          image: buildingConstruction0,
          date: "20200618",
          title: "18/06/2020",
          text: "O início da obra: Trabalho de terraplenagem",
        },
        {
          image: buildingConstruction1,
          date: "20201022",
          title: "22/10/2020",
          text: "A estrutura já 'cresceu' até o segundo andar",
        },
        {
          image: buildingConstruction2,
          date: "20201028",
          title: "28/10/2020",
          text: "Começamos o terceiro andar",
        },
        {
          image: buildingConstruction3,
          date: "20201104",
          title: "04/11/2020",
          text: "Terceiro andar com a estrutura finalizada",
        },
        {
          image: buildingConstruction4,
          date: "20201110",
          title: "10/11/2020",
          text: "Quarto andar em progresso",
        },        
        {
          image: buildingConstruction5,
          date: "20201120",
          title: "20/11/2020",
          text: "Chegamos ao topo",
        },               
        {
          image: buildingConstruction6,
          date: "20201202",
          title: "02/12/2020",
          text: "Estrutura finalizada",
        },        
        {
          image: buildingConstruction7,
          date: "20201204",
          title: "02/12/2020",
          text: "Vista do 3º andar para a rua Dr. José Justiniano",
        },        
        {
          image: buildingConstruction8,
          date: "20201205",
          title: "02/12/2020",
          text: "Vista do 3º andar para a rua Dr. Manuel Barbosa",
        },        
        {
          image: buildingConstruction9,
          date: "20201206",
          title: "02/12/2020",
          text: "Vista para o campo",
        },        
        {
          image: buildingConstruction10,
          date: "20201203",
          title: "02/12/2020",
          text: "Visão do interior da Estrutura",
        },        
        {
          image: buildingConstruction11,
          date: "20210313",
          title: "13/02/2021",
          text: "Fase do assentamento de alvenarias",
        },        
        {
          image: buildingConstruction12,
          date: "20210313",
          title: "13/02/2021",
          text: "Fase do assentamento de alvenarias",
        },
        {
          image: buildingConstruction13,
          date: "20210506",
          title: "06/05/2021",
          text: "Execução da cobertura",
        }, 
        {
          image: buildingConstruction14,
          date: "20210722",
          title: "22/07/2021",
          text: "Aplicação do revestimento exterior",
        }, 
        {
          image: buildingConstruction15,
          date: "20210813",
          title: "13/08/2021",
          text: "Aplicação do isolamento térmico e revestimento",
        },
        {
          image: buildingConstruction16,
          date: "20220203",
          title: "04/02/2022",
          text: "Revestimento wc",
        },
        {
          image: buildingConstruction17,
          date: "20220204",
          title: "04/02/2022",
          text: "Início da instalação das caixilharias",
        },
        {
          image: buildingConstruction18,
          date: "20220602",
          title: "02/06/2022",
          text: "Revestimeto da fachada",
        },
        {
          image: buildingConstruction19,
          date: "20220603",
          title: "02/06/2022",
          text: "Início das pinturas",
        },    
        {
          image: buildingConstruction20,
          date: "20220604",
          title: "02/06/2022",
          text: "Pavimento da varanda",
        },    
        {
          image: buildingConstruction21,
          date: "20220605",
          title: "02/06/2022",
          text: "Piso da varanda",
        },                  
        {
          image: buildingConstruction22,
          date: "20220926",
          title: "26/09/2022",
          text: "Acabamentos da casa de banho I",
        },    
        {
          image: buildingConstruction23,
          date: "20220927",
          title: "26/09/2022",
          text: "Acabamentos da casa de banho II",
        },    
        {
          image: buildingConstruction24,
          date: "20220928",
          title: "26/09/2022",
          text: "Acabamentos da casa de banho III",
        },    
        {
          image: buildingConstruction25,
          date: "20220929",
          title: "26/09/2022",
          text: "Acabamentos da casa de banho IV",
        },    
        {
          image: buildingConstruction26,
          date: "20220930",
          title: "26/09/2022",
          text: "Acabamentos da casa de banho V",
        },    
        {
          image: buildingConstruction27,
          ate: "20220931",
          title: "26/09/2022",
          text: "Colocação das guardas em vidro",
        },    
        {
          image: buildingConstruction28,
          date: "20220932",
          title: "26/09/2022",
          text: "Vista poente",
        },    
        {
          image: buildingConstruction29,
          date: "20220933",
          title: "26/09/2022",
          text: "Pormenores dos acabamentos interiores",
        }, 
         {
          image: buildingConstruction30,
          date: "20230815",
          title: "15/08/2023",
          text: "T4: Vista para o baixo vouga",
        }, 
        {
          image: buildingConstruction31,
          date: "20230814",
          title: "15/08/2023",
          text: "T4: Sala com muita luz natural",
        },
        {
          image: buildingConstruction32,
          date: "20230813",
          title: "15/08/2023",
          text: "T4: Pormenor da cozinha - ilha",
        }, 
        {
          image: buildingConstruction33,
          date: "20230812",
          title: "15/08/2023",
          text: "T4: Estores elétricos",
        }, 
        {
          image: buildingConstruction34,
          date: "20230811",
          title: "15/08/2023",
          text: "T4: Detalhe da varanda",
        }, 
        {
          image: buildingConstruction35,
          date: "20230810",
          title: "15/08/2023",
          text: "T4: Armários embutidos e ar condicionado em todos os quartos",
        }, 
        {
          image: buildingConstruction36,
          date: "20230809",
          title: "15/08/2023",
          text: "T4: Detalhe do corredor",
        }, 
        {
          image: buildingConstruction37,
          date: "20230808",
          title: "15/08/2023",
          text: "T4: Vista de um dos quartos",
        }, 
        {
          image: buildingConstruction38,
          date: "20230807",
          title: "15/08/2023",
          text: "T4: Detalhe de 1 das 3 casas de banho",
        }, 
        {
          image: buildingConstruction39,
          date: "20230806",
          title: "15/08/2023",
          text: "T4: Bancada e eletrodomésticos da cozinha",
        }, 
        {
          image: buildingConstruction40,
          date: "20230805",
          title: "15/08/2023",
          text: "T4: Varanda ampla e com uma excelente vista",
        }, 
        {
          image: buildingConstruction41,
          date: "20230804",
          title: "15/08/2023",
          text: "T4: Detalhe do chuveiro de uma das casas de banho",
        }, 
        {
          image: buildingConstruction42,
          date: "20230803",
          title: "15/08/2023",
          text: "T4: Pormenor de luz natural e indireta de um dos quartos",
        }, 
        {
          image: buildingConstruction43,
          date: "20230802",
          title: "15/08/2023",
          text: "T4: Pormenor da sala e da varanda",
        }, 
        {
          image: buildingConstruction44,
          date: "20230801",
          title: "15/08/2023",
          text: "T4: Espaços amplos e luminosos",
        }, 
          ];

      return newsToShow;
 }