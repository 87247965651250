import React from "react";
//visual elements (video, igms and gifs)
import asterisco from "../../images/logo_asterisco.png";
import linharesVidal from "../../images/logo_linhares.png";
import mioconcept from "../../images/logo_mioconcept.png";

//Css code
import "./Partners.css";

function Partners() {

  //element: text to know more about the partners
  const textknowPartners = () => {
    return (
      <h5 className="textPartner">
        Conheça melhor as entidades envolvidas neste projecto
      </h5>
    )
  }

  //element: 2bConnect logo
  const linharesVidalLogo = () => {
    return (
      <a href="http://www.linharesvidal.pt/" target="blank">
        <img
          className="logoPartner linharesVidal"
          src={linharesVidal}
          alt="Linhares Vidal Logo"
        />
      </a>
    )
  }
  //element: mioconcept logo
  const mioconceptLogo = () => {
    return (
      <a href="https://www.mioconcept.com/" target="blank">
        <img
          className="logoPartner mioconcept"
          src={mioconcept}
          alt="mioconcept"
        />
      </a>
    )
  }

  //element: Aveiro tech city logo
  const asteriscoLogo = () => {
    return (
      <a href="https://www.facebook.com/asteriscoarquitetura" target="blank">
        <img
          className="logoPartner asterisco"
          src={asterisco}
          alt="Aveiro Tech City Logo"
        />
      </a>
    )
  }

  return (
    <div id="partnersContainer">
      <div className="partnerElements">
        {textknowPartners()}
        <div className="logosPartner">
          {linharesVidalLogo()}
          {mioconceptLogo()}
          {asteriscoLogo()}
        </div>
      </div>
    </div>
  );
}

export default Partners;
