import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import sold from "../../images/vendido.png";
import infoApartments from "./DescriptionObj";
import "./Description.css";

export default function Description() {
  const [isOpen, setIsOpen] = useState(false);
  const [sectionDisplayed, setSectionDisplayed] = useState(1);
  const [elementActive, setElementActive] = useState("letterG");
  const [source, setSource] = useState();

  const apartmentsInfo = infoApartments();


  const lightbox = () => {
    return (
      <div>
        {isOpen && (
          <Lightbox mainSrc={source} onCloseRequest={() => setIsOpen(false)} />
        )}
      </div>
    );
  }; 

  const cubeClick = (src) => {
    setSource(src);
    setIsOpen(true);
  };

  const cube = () => {
    return (
      <div className="cubeContainer">
        <input
          id="radio1"
          type="radio"
          name="css-tabs"
          onClick={() => setSectionDisplayed(1)}
        />
        <input
          id="radio2"
          type="radio"
          name="css-tabs"
          onClick={() => setSectionDisplayed(2)}
        />
        <div id="tabs">
          <label
            id="tab1"
            htmlFor="radio1"
            style={{
              backgroundColor: sectionDisplayed === 1 ? "#3a6078" : "#6cd4d4",
            }}
          >
            Descrição
          </label>
          <label
            id="tab2"
            htmlFor="radio2"
            style={{
              backgroundColor: sectionDisplayed === 1 ? "#6cd4d4" : "#3a6078",
            }}
          >
            Planta
          </label>
        </div>
        <div id="content">
        {apartmentsInfo.map((el) =>
                el.id === elementActive && el.sold === true ? <img src={sold} alt="Vendido" className="soldImg"></img> : null
              )}
          {sectionDisplayed === 1 ? (
            <section id={sectionDisplayed === 1 ? "content1 active" : null}>
              {apartmentsInfo.map((el) =>
                el.id === elementActive ? el.description : null
              )}
            </section>
          ) : (
            <section id={sectionDisplayed === 2 ? "content2 active" : null}>
              <div className="mapImg" style={{ cursor: "pointer" }}>
                {apartmentsInfo.map((el) => {
                  if (el.id === elementActive) {
                    return el.plant ? (
                      <div>
                        {el.plant.map((src) => (
                          <img
                            id={el.id}
                            className={el.id === "letterG" ? "t3planG" : "plant"}
                            src={src}
                            alt="Planta"
                            onClick={() => cubeClick(src)}
                          />
                        ))}
                      </div>
                    ) : (
                      <p> Informação não disponível no momento </p>
                    );
                  } 
                  else {return null}
                })}
                {lightbox()}
              </div>
            </section>
          )}
        </div>
      </div>
    );
  };

  const navbarClick = (el) => {
    setSectionDisplayed(1);
    setElementActive(el);
  };

  const sidebar = () => {
    return (
      <div className="sidebar2">
        <ul className="nav nav-tabs">
          {apartmentsInfo.map((el) => (
            <li className="nav-item" id={el.id} onClick={() => navbarClick(el.id)} key={el.id}>
              {el.id === elementActive ? (
                <a className="nav-link active">{el.name}</a>
              ) : (
                <a className="nav-link" >{el.name}</a>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div id="descriptionContainer">
      {sidebar()}
      {cube()}
    </div>
  );
}
