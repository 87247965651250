import React, { useState } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import t3_Balcony from "../../images/T3_Balcony.jpg";
import t3_InsideSuite_1 from "../../images/T3_InsideSuite_1.jpg";
import t3_InsideSuite_2 from "../../images/T3_InsideSuite_2.jpg";
import t3_Kitchen_1 from "../../images/T3_Kitchen_1.jpg";
import t3_Kitchen_2 from "../../images/T3_Kitchen_2.jpg";
import t3_LivingRoom_1 from "../../images/T3_LivingRoom_1.jpg";
import t3_LivingRoom_2 from "../../images/T3_LivingRoom_2.jpg";
import t3_LivingRoom_3 from "../../images/T3_LivingRoom_3.jpg";
import t3_LivingRoom_4 from "../../images/T3_LivingRoom_4.jpg";
import t3_RoomI from "../../images/T3_RoomI.jpg";
import t3_RoomII_1 from "../../images/T3_RoomII_1.jpg";
import t3_RoomII_2 from "../../images/T3_RoomII_2.jpg";
import t3_RoomII_3 from "../../images/T3_RoomII_3.jpg";
import t3_RoomIII_Suite from "../../images/T3_RoomIII_Suite.jpg";
import outsideView_1 from "../../images/outsideView1.jpg";
import outsideView_2 from "../../images/outsideView2.jpg";
import outsideView_3 from "../../images/outsideView3.jpg";
import "./Carousel.css";

export default function Carousel() {

    const images = [
        { src: t3_Balcony, id: 0, text: "Excelente exposição solar" },
        { src: t3_InsideSuite_1, id: 1, text: "O futuro é hoje" },
        { src: t3_InsideSuite_2, id: 2, text: "Acabamentos de qualidade" },
        { src: t3_Kitchen_1, id: 3, text: "Design moderno e funcional" },
        { src: t3_Kitchen_2, id: 4, text: "Não esquecemos dos detalhes" },
        { src: t3_LivingRoom_1, id: 5, text: "Imagine-se a desfrutar da sua casa" },
        { src: t3_LivingRoom_2, id: 6, text: "Bem vindo" },
        { src: t3_LivingRoom_3, id: 7, text: "Trabalho, amigos ou lazer" },
        { src: t3_LivingRoom_4, id: 8, text: "'Onde as nossas histórias são escritas'" },
        { src: t3_RoomI, id: 9, text: "Isolamento acústico e térmico" },
        { src: t3_RoomIII_Suite, id: 10, text: "Privacidade, silêncio e conforto" },
        { src: t3_RoomII_1, id: 11, text: "Localizado no centro da cidade" },
        { src: t3_RoomII_2, id: 12, text: "Elegante e versátil" },
        { src: t3_RoomII_3, id: 13, text: "Conforto e qualidade" },
        { src: outsideView_1, id: 14, text: "Arquitetura contemporânea" },
        { src: outsideView_2, id: 15, text: "Linhas arrojadas" },
        { src: outsideView_3, id: 16, text: "Moderno" },
    ];

    const [activeImg, setActiveImg] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const imgClick = (e) => {
        e.preventDefault();
        setActiveImg(parseInt(e.target.id));
        setIsOpen(true);
    }

    const carousel = () => {
        return (
            <div className="container-fluid px-0 cardcontainer" id="picturesContainer">
                <div id="carouselExampleControls" interval={5000} className="carousel slide" data-ride="carousel" data-pause={false}>
                    <div className="carousel-inner bg-info" role="listbox">
                        {images.map(element =>
                            element.id === activeImg ?
                                (
                                    <div className={"carousel-item active"} key={element.id}>
                                        <div className="d-flex align-items-center justify-content-center min-vh-100">
                                            <img className="d-block w-100 slideshow" onClick={imgClick} id={element.id} src={element.src} alt={element.id} mode="fit"

                                            />
                                        </div>
                                    </div>

                                ) :
                                (
                                    <div className={"carousel-item"} key={element.id}>
                                        <div className="d-flex align-items-center justify-content-center min-vh-100">
                                            <img className="d-block w-100 slideshow" onClick={imgClick} id={element.id} src={element.src} alt={element.id} mode="fit"
                                            />
                                        </div>
                                    </div>

                                )
                        )}
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>
        )
    }

    const lightbox = () => {
        const imgIndex1 = activeImg === 0 ? images.length - 1 : activeImg - 1;
        const imgIndex2 = activeImg === images.length - 1 ? 0 : activeImg + 1;
        return (
            <div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[activeImg].src}
                        nextSrc={images[imgIndex2].src}
                        prevSrc={images[imgIndex1].src}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setActiveImg(imgIndex1)
                        }
                        onMoveNextRequest={() =>
                            setActiveImg(imgIndex2)
                        }
                    />
                )}
            </div>
        );
    }

    return (
        <div id="containerVideoElements">
            {carousel()}
            {lightbox()}
        </div>
    )
}
