import React, { Fragment } from "react";
//Map import
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "./Map.css";

export default function DumpsterLocation() {

    const center = [40.749400, -8.572118];

    const map = (
        <div id="mapContainer">
            <MapContainer className="map" center={center} zoom={15}>
                <TileLayer
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}.png"
                    maxZoom="18"
                    attribution=''
                />
                <Fragment>
                    <Marker position={center}>
                        <Popup >Antuã Terrace <br></br> Rua Dr. José Justiniano - Estarreja <br></br> 40.749400, -8.572118</Popup>
                    </Marker>
                </Fragment>
            </MapContainer></div>
    );
    return map;
}

/*


*/
