import React from "react";
import About from "./About";
import Map from "./Map";

export default function Contacts() {
  return (
    <div>
      <About />
      <Map />
    </div>
  );
}
