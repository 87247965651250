import React from "react";
import t3_Balcony from "../../images/T3_Balcony.jpg";
import t3_InsideSuite_1 from "../../images/T3_InsideSuite_1.jpg";
import t3_Kitchen_1 from "../../images/T3_Kitchen_1.jpg";
import t3_Kitchen_2 from "../../images/T3_Kitchen_2.jpg";
import t3_LivingRoom_1 from "../../images/T3_LivingRoom_1.jpg";
import t3_LivingRoom_3 from "../../images/T3_LivingRoom_3.jpg";
import t3_RoomI from "../../images/T3_RoomI.jpg";
import t3_RoomII_3 from "../../images/T3_RoomII_3.jpg";
import t3_RoomIII_Suite from "../../images/T3_RoomIII_Suite.jpg";

import "./SpinningPictures.css";

export default function SpinningPictures() {

    const images = [t3_Balcony, t3_InsideSuite_1, t3_Kitchen_1, t3_Kitchen_2, t3_LivingRoom_1, t3_LivingRoom_3, t3_RoomI, t3_RoomIII_Suite, t3_RoomII_3];


    return (
        <div className="images3dContainer">
            <div className="spinningOuter">
                <div className="spinningInner">
                    {images.map(element => <figure className="spinningImgs" key={element.toString()}><img src={element} alt={element.toString()}/></figure>)}
                </div>
            </div>
                  
        </div>
    )
}