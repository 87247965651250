import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import "./About.css";

export default function About() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    registerMail();
  }, []);

  const handleChangeName = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };
  const handleChangeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const handleChangeMessage = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };
  const handleChangePhone = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (name === "" || email === "" || message === "") {
    }

    let newContact = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };

    axios
      .post("http://antuaterrace.com/php/src/index.php", newContact)
      .then(setName(""), setEmail(""), setPhone(""), setMessage(""))
      .then(mailSent());
  };

  const mailSent = () => {
    toast.success(
      "A sua mensagem foi enviada com sucesso! Agradecemos o seu contacto e retornaremos em breve!",
      {
        toastId: "mailSentSucessful",
      }
    );
  };
  const registerMail = () => {
    toast.info(
      "Envie-nos uma mensagem e receba um alerta por email quando o site for atualizado com mais informações e fotos!",
      {
        toastId: "mailSentSucessful",
      }
    );
  };

  const textContacts = () => {
    return (
      <div className="textContacts">
        <div>
          <h2>
            <i className="fa fa-map-marker" aria-hidden="true"></i> Rua Dr. José
            Justiniano - Estarreja{" "}
          </h2>
          <h2>
            <i className="fa fa-phone" aria-hidden="true"></i> 933943112 (Chamada para rede móvel nacional){" "}
          </h2>
        </div>
        <div className="socialmediaContacts">
          <h2>
            <i className="fa fa-facebook-official" aria-hidden="true"></i> Siga-nos
            no{" "}
            <a
              href={
                "https://www.facebook.com/Antu%C3%A3-Terrace-105770097992071"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              facebook
            </a>{" "}
          </h2>
        </div>
        <div>
          <h2>
            <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
            antuaterrace@gmail.com{" "}
          </h2>
          <h2>
            <i className="fa fa-home" aria-hidden="true"></i> www.antuaterrace.pt{" "}
          </h2>
        </div>
      </div>
    );
  };

  const form = () => {
    return (
      <div className="contactFormContainer">
        <form id="contact" onSubmit={onSubmit}>
          <h1>Contacte-nos!</h1>
          <fieldset>
            <input
              placeholder="Seu Nome"
              value={name}
              onChange={handleChangeName}
              type="text"
              tabIndex="1"
              required
              autoFocus
            />
          </fieldset>
          <fieldset>
            <input
              placeholder="Seu endereço de email"
              value={email}
              onChange={handleChangeEmail}
              type="email"
              tabIndex="2"
              required
            />
          </fieldset>
          <fieldset>
            <input
              placeholder="Seu número para contacto (opcional)"
              value={phone}
              onChange={handleChangePhone}
              type="tel"
              tabIndex="3"
            />
          </fieldset>
          <fieldset>
            <textarea
              placeholder="Escreva a sua mensagem aqui"
              value={message}
              onChange={handleChangeMessage}
              tabIndex="5"
              required
            ></textarea>
          </fieldset>
          <fieldset>
            <button
              name="submit"
              type="submit"
              id="contact-submit"
              data-submit="...Sending"
            >
              Enviar
            </button>
          </fieldset>
          <p className="copyright">Antuã Terrace</p>
        </form>
      </div>
    );
  };

  return (
    <div className="aboutContainer" id="contactContainer">
      {form()}
      {textContacts()}
    </div>
  );
}
