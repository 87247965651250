import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
//Bootstrap elements
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
//Visual elements (video, igms and gifs)
//import trashReadingPaper from "../../visualElements/images/trashReadingPaper.png";
//Css code
import "./News.css";
//News: date, initial text, title, image and link
import newsToShow from "./NewsObjects";

export default class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsToShow: newsToShow(),
      currentPage: 0,
      elementsPerPage: 3,
      src: "",
      isOpen: false,
    };
  }

  //method to cut text to 200 characters maximum and to not have any incomplete word at the end
  //used to have all card elements with the same text size
  determineStringSize = (text) => {
    var newText = text;
    if (text.length > 200) {
      newText = text.substring(0, 200);
      newText = newText.substring(0, newText.lastIndexOf(" ")) + "\n(...)";
    } else {
      newText = text;
      newText = newText.substring(0, newText.lastIndexOf(" ")) + "\n(...)";
    }
    return newText;
  };

  //method to create one card element per new: img, text and link
  elementsToShow() {
    const { currentPage, newsToShow, elementsPerPage } = this.state;
    return newsToShow
      .slice(0, currentPage * elementsPerPage + elementsPerPage)
      .map((el) => (
        <div className="col-md-4 col-sm-4 newsElement" key={el.image.toString()}>
          <Card className="card2" onClick={() => {
            return this.updateStatus(el.image);
          }}>
            <div className="cardImgContainer">
           
                <img className="cardImg" top="true" src={el.image} alt={el.image.toString()} />
              
            </div>
            <CardBody>
              <div>
                <CardTitle style={{ fontWeight: "bold" }}>{el.title}</CardTitle>
                <CardText className="newsTextCard">{el.text}</CardText>
              </div>
            </CardBody>
          </Card>
        </div>
      ));
  }

  updateStatus = (props) => {
    this.setState({
      src: props,
      isOpen: true,
    });
  };

  //method to show more news
  seeMore = () => {
    this.setState({
      currentPage: this.state.currentPage + 1,
    });
  };

  //method to show less news
  seeLess = () => {
    this.setState({
      currentPage: 0,
    });
  };

  //method to organize news by date
  organizeNewsByDate = () => {
    const sortedNews = this.state.newsToShow.sort((a, b) => b.date - a.date);
    this.setState({
      newsToShow: sortedNews,
    });
  };

  lightbox = () => {
    return (
      <div>
        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.state.src}
            onCloseRequest={() =>
              this.setState({
                isOpen: false,
              })
            }
          />
        )}
      </div>
    );
  };

  componentDidMount = () => {
    this.organizeNewsByDate();
  };

  render() {
    return (
      <div className="containerNews" id="newsContainer">
        <div className="headNews">
          <h1 className="textNews">Acompanhe o nosso progresso </h1>
        </div>
        <div className="elementsToShowContainer row">
          {this.elementsToShow()}
        </div>
        <div>{this.lightbox()}</div>
        <div
          style={{ display: "flex-row", justifyContent: "end", margin: "50px" }}
        >
          {this.state.currentPage <
          (this.state.newsToShow.length / 3).toFixed(0) - 1 ? (
            <div className="seeMore">
              <button className="newsToShowBtn" onClick={this.seeMore}>
                Ver mais
              </button>
            </div>
          ) : null}
          {this.state.index1 === this.state.newsToShow.length ||
          this.state.currentPage > 0 ? (
            <div className="seeLess">
              <button className="newsToShowBtn" onClick={this.seeLess}>
                Ver menos
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
