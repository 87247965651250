import React, { useState, useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCamera, faInfoCircle, faCalendarPlus, faAt, faMapMarkedAlt, faHandshake } from '@fortawesome/free-solid-svg-icons';
import Navbar from "react-bootstrap/Navbar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Nav from "react-bootstrap/Nav";
import "./Navbar.css";

export default function NavbarElement() {
  const [options, setOptions] = useState([]);
  const [expanded, setExpanded] = useState();
  const [active, setActiveItem] = useState("homeContainer");
  const [offSetTopp, setOffsetTopp] = useState(0);
  const [scrollOffsetCarousel, setscrollOffsetCarousel] = useState(0);

  useEffect(() => {
    setscrollOffsetCarousel(scrollOffsetPicturesContainer());
    setOffsetTopp(window.pageYOffset);
    console.log(window.pageYOffset);
    setExpanded(handleResize());
    window.addEventListener("resize", handleResize);
    setOptions(navbarElements);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    function handleScroll() {
      if (
        (window.innerHeight + (window.innerHeight * 0.1))>=
        Math.round(
          document.getElementById("root").getBoundingClientRect().bottom
        )
      ) {
        setActiveItem(navbarElements[navbarElements.length - 1].id);
        return;
      }

      var counts = [];
      navbarElements.map((el) =>
        counts.push(document.getElementById(el.id).getBoundingClientRect().top)
      );
      const goal = 0;

      var closest = counts.reduce(function (prev, curr) {
        return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
      });

      const activeEl = navbarElements[counts.indexOf(closest)];
      setActiveItem(activeEl.id);
      setOffsetTopp(window.pageYOffset);
    }

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.addEventListener("scroll", handleScroll, { passive: true });
    };
  }, [offSetTopp]);

  const navbarElements = [
    {
      id: "homeContainer",
      option: "Início",
      icon: <FontAwesomeIcon id="icon" icon={faHome} />,
      scrollOffset: 0,
    },
    {
      id: "picturesContainer",
      option: "Imagens",
      icon: <FontAwesomeIcon id="icon" icon={faCamera} />,
      scrollOffset: scrollOffsetCarousel,
    },
    {
      id: "descriptionContainer",
      option: "Descrição dos apartamentos",
      icon: <FontAwesomeIcon id="icon" icon={faInfoCircle} />,
      scrollOffset: 10,
    },
    {
      id: "newsContainer",
      option: "Novidades",
      icon: <FontAwesomeIcon icon={faCalendarPlus} />,
      scrollOffset: 0,
    },
    {
      id: "contactContainer",
      option: "Contactos",
      icon: <FontAwesomeIcon id="icon" icon={faAt} />,
      scrollOffset: 0,
    },
    {
      id: "mapContainer",
      option: "Localização",
      icon: <FontAwesomeIcon icon={faMapMarkedAlt} />,
      scrollOffset: 0,
    },
    {
      id: "partnersContainer",
      option: "Parceiros",
      icon: <FontAwesomeIcon id="icon" icon={faHandshake} />,
      scrollOffset: 0,
    },
  ];

  //method to centralize the view when scrolling to pictures container
  const scrollOffsetPicturesContainer = () => {
    const scrollOffsetObj = document
      .getElementById("picturesContainer")
      .getBoundingClientRect();
    const divHeight = scrollOffsetObj.height;
    const carouselscrollOffset = window.innerWidth > 850 ? divHeight * 0.3 : 0;
    setscrollOffsetCarousel(carouselscrollOffset);
  };

  const handleResize = () => {
    scrollOffsetPicturesContainer();
    setOffsetTopp(window.pageYOffset);
    if (window.innerWidth >= 850) {
      setExpanded(true);
      return true;
    } else {
      setExpanded(false);
      return false;
    }
  };

  return (
    <div className="allElements">
      <Navbar className="navigation" id="navigation">
        <Nav className="mr-auto"></Nav>
        <Nav id="linkk">
          {options.map((el) => (
            <Link
              key={el.id}
              to={el.id}
              spy={true}
              smooth={true}
              duration={750}
              offset={
                el.id !== "picturesContainer"
                  ? el.scrollOffset
                  : scrollOffsetCarousel
              }
            >
              <OverlayTrigger
                key="left"
                trigger={['hover', 'focus']}
                placement="left"
                overlay={
                  expanded
                    ? ({
                        placement,
                        arrowProps,
                        show: _show,
                        popper,
                        ...props
                      }) => (
                        <div
                          {...props}
                          style={{
                            backgroundColor: "#3a6078",
                            padding: "2px 10px",
                            color: "white",
                            borderRadius: 3,
                            fontFamily: "var(--font-family-orbitron)",
                            ...props.style,
                          }}
                        >
                          {el.option}
                        </div>
                      )
                    : ({
                        placement,
                        arrowProps,
                        show: _show,
                        popper,
                        ...props
                      }) => (
                        <div
                          {...props}
                          style={{
                            display: "none",
                            ...props.style,
                          }}
                        />
                      )
                }
              >
                <button
                  style={
                    el.id === active ? { color: "#6cd4d4" } : { color: "white" }
                  }
                  id="options"
                  data-tag={el.id}
                  href={"#" + el.id}
                >
                  {el.icon}{" "}
                </button>
              </OverlayTrigger>
            </Link>
          ))}
        </Nav>
      </Navbar>
    </div>
  );
}
