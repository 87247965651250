import React from "react";
import mapImg from "../../images/T3_plan_transparent.jpg";
import plantA from "../../images/plantA.png";
import plantB from "../../images/plantB.png";
import plantC from "../../images/plantC.png";
import plantD from "../../images/plantD.png";
import plantE from "../../images/plantE.png";
import plantF from "../../images/plantF.png";
import plantG from "../../images/plantG.png";
import plantH from "../../images/plantH.png";
import plantI from "../../images/plantI.png";

export default function () {

    const apartmentsInfo = [
        {
          id: "letterA",
          plant: [plantA],
          name: "1º piso. T2. A",
          sold: true,
          description: (
            <p key="letterA">
              <strong>Designação:</strong> Fração Autónoma A<br></br>
              <strong>Tipologia:</strong> T2 <br></br>
              <strong>Localização:</strong> Piso 1 frente esquerdo<br></br>
              <strong>Composição:</strong> Hall, cozinha, sala comum, dois quartos,
              duas instalações sanitárias e um lugar de estacionamento localizado na
              cave e identificado pela letra A<br></br>
              <strong>Área:</strong> 95.96m²<br></br>
              <strong>Área da Varanda:</strong> 9.78m² <br></br>
              <strong>Área do Estacionamento:</strong> 15.27m² <br></br>
            </p>
          ),
        },
        {
          id: "letterB",
          plant: [plantB],
          name: "1º piso. T3. B",
          sold: true,
          description: (
            <p key="letterB">
              <strong>Designação:</strong> Fração Autónoma B<br></br>
              <strong>Tipologia:</strong> T3 <br></br>
              <strong>Localização:</strong> Piso 1 posterior esquerdo<br></br>
              <strong>Composição:</strong> Hall, cozinha, lavandaria, sala comum,
              três quartos, três instalações sanitárias e dois lugares de
              estacionamento localizados na cave e identificados pela letra B
              <br></br>
              <strong>Área:</strong> 132.22m²<br></br>
              <strong>Área da Varanda:</strong> 20.19m² <br></br>
              <strong>Área do Estacionamento:</strong> 30.45m² <br></br>
            </p>
          ),
        },
        {
          id: "letterC",
          plant: [plantC],
          name: "1º piso. T2. C",
          sold: true,
          description: (
            <p key="letterC">
              <strong>Designação:</strong> Fração Autónoma C<br></br>
              <strong>Tipologia:</strong> T2 <br></br>
              <strong>Localização:</strong> Piso 1 direito<br></br>
              <strong>Composição:</strong> Hall, cozinha, sala comum, dois quartos,
              duas instalações sanitárias e um lugar de estacionamento localizado na
              cave e identificado pela letra C<br></br>
              <strong>Área:</strong> 101.27m²<br></br>
              <strong>Área da Varanda:</strong> 6.10m² <br></br>
              <strong>Área do Estacionamento:</strong> 15.27m² <br></br>
            </p>
          ),
        },
        {
          id: "letterD",
          plant: [plantD],
          name: "2º piso. T2. D",
          sold: true,
          description: (
            <p key="letterD">
              <strong>Designação:</strong> Fração Autónoma D<br></br>
              <strong>Tipologia: </strong>T2 <br></br>
              <strong>Localização:</strong> Piso 2 frente esquerdo<br></br>
              <strong>Composição:</strong> Hall, cozinha, sala comum, dois quartos,
              duas instalações sanitárias e um lugar de estacionamento localizado na
              cave e identificado pela letra D<br></br>
              <strong>Área:</strong> 95.96m²<br></br>
              <strong>Área da Varanda:</strong> 9.78m² <br></br>
              <strong>Área do Estacionamento:</strong> 15.27m² <br></br>
            </p>
          ),
        },
        {
          id: "letterE",
          plant: [plantE],
          name: "2º piso. T3. E",
          sold: true,
          description: (
            <p key="letterE">
              <strong>Designação:</strong> Fração Autónoma E<br></br>
              <strong>Tipologia:</strong> T3 <br></br>
              <strong>Localização:</strong> Piso 2 posterior esquerdo<br></br>
              <strong>Composição:</strong> Hall, cozinha, lavandaria, sala comum,
              três quartos, três instalações sanitárias e dois lugares de
              estacionamento localizados na cave e identificados pela letra E
              <br></br>
              <strong>Área:</strong> 132.22m²<br></br>
              <strong>Área da Varanda:</strong> 20.19m² <br></br>
              <strong>Área do Estacionamento:</strong> 30.45m² <br></br>
            </p>
          ),
        },
        {
          id: "letterF",
          plant: [plantF],
          name: "2º piso. T2. F",
          sold: true,
          description: (
            <p key="letterF">
              <strong>Designação:</strong> Fração Autónoma F<br></br>
              <strong>Tipologia:</strong> T2 <br></br>
              <strong>Localização:</strong> Piso 2 direito<br></br>
              <strong>Composição:</strong> Hall, cozinha, sala comum, dois quartos,
              duas instalações sanitárias e um lugar de estacionamento localizado na
              cave e identificado pela letra F<br></br>
              <strong>Área:</strong> 101.27m²<br></br>
              <strong>Área da Varanda:</strong> 6.10m² <br></br>
              <strong>Área do Estacionamento:</strong> 15.27m² <br></br>
            </p>
          ),
        },
        {
          id: "letterG",
          plant: [mapImg, plantG],
          name: "3º piso. T3. G",
          sold: true,
          description: (
            <p key="letterG">
              <strong>Designação:</strong> Fração Autónoma G<br></br>
              <strong>Tipologia:</strong> T3 <br></br>
              <strong>Localização:</strong> Piso 3 esquerdo<br></br>
              <strong>Composição:</strong> Hall, cozinha, sala comum, três quartos,
              três instalações sanitárias e dois lugares de estacionamento
              localizados na cave e identificados pela letra G<br></br>
              <strong>Área:</strong> 135.60m²<br></br>
              <strong>Área da Varanda:</strong> 69.43m² <br></br>
              <strong>Área do Estacionamento:</strong> 30.04m² <br></br>
            </p>
          ),
        },
        {
          id: "letterH",
          plant: [plantH],
          name: "3º piso. T2. H",
          sold: true,
          description: (
            <p key="letterH">
              <strong>Designação:</strong> Fração Autónoma H<br></br>
              <strong>Tipologia:</strong> T2 <br></br>
              <strong>Localização:</strong> Piso 3 direito<br></br>
              <strong>Composição:</strong> Hall, cozinha, sala comum, dois quartos,
              duas instalações sanitárias e dois lugares de estacionamento
              localizados na cave e identificados pela letra H<br></br>
              <strong>Área:</strong> 96.94m²<br></br>
              <strong>Área da Varanda:</strong> 30.68m² <br></br>
              <strong>Área do Estacionamento:</strong> 30.04m² <br></br>
            </p>
          ),
        },
        {
          id: "letterI",
          plant: [plantI],
          name: "4º piso. T4. I",
          sold: false,
          description: (
            <p key="letterI">
              <strong>Designação:</strong> Fração Autónoma I<br></br>
              <strong>Tipologia:</strong> T4 <br></br>
              <strong>Localização:</strong> Piso 4<br></br>
              <strong>Composição:</strong> Hall, cozinha, lavandaria, sala comum,
              quatro quartos, três instalações sanitárias e dois lugares de
              estacionamento localizados na cave e identificados pela letra I
              <br></br>
              <strong>Área:</strong> 165.06m²<br></br>
              <strong>Área da Varanda:</strong> 94.94m² <br></br>
              <strong>Área do Estacionamento:</strong> 33.34m² <br></br>
            </p>
          ),
        },
      ];

      return apartmentsInfo;
    }