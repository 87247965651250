import React, { Fragment } from "react";
import GeneralInfo from "./components/generalInfo/GeneralInfo";
import FirstSection from "./components/home/FirstSection";
import SecondSection from "./components/pictures/Carousel";
import ThirdSection from "./components/description/Description";
import FourthSection from "./components/updates/News";
import FifthSection from "./components/contacts/Contact";
import SixthSection from "./components/partners/Partners";

function App() {
  return (
    <Fragment>
      <GeneralInfo />     
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
    </Fragment>
  );
}

export default App;
